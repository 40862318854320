import React from "react"
import styles from "../styles/components/Hero.module.css"

export default function Hero() {
  return (
    <div className={styles.hero}>
      <h1>Darband</h1>
      <p>persische Spezialitäten</p>
    </div>
  )
}
