export const Menu = [
  {
    name: "Menü 1",
    imageName: "m1.webp",
  },
  {
    name: "Menü 2",
    imageName: "m2.webp",
  },
  {
    name: "Menü 3",
    imageName: "m3.webp",
  },
  {
    name: "Menü 4",
    imageName: "m4.webp",
  },
  {
    name: "Menü 5",
    imageName: "m5.webp",
  },
]
